@layer modules, ui, base;
@layer base {
  .BlogIndex_main__8tq5H {
  overflow: hidden;
}

.BlogIndex_headerWrapper__WJHii {
  position: relative;
}

.BlogIndex_headerWrapper__WJHii::before {
  content: "";
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: 10px;
  right: -110px;
  width: 211px;
  height: 193px;
  background-image: var(--backgroundImage);
  background-size: cover;
  rotate: 45deg;
}

.BlogIndex_title__2qMSg {
  margin-bottom: var(--spacer-200);
}

.BlogIndex_filters__EHTk6 {
  position: relative;
  /* TODO: what is the current stacking context? */
  z-index: var(--z-up-in-the-current-stacking-context); /* to override some z-index inside Cards, below the FilterBar */
}

.BlogIndex_image__XaG5A {
  width: 100%;
  height: auto;
}

.BlogIndex_cover__RDFTQ {
  display: block;
}

.BlogIndex_relatedCard__Wd0ZF {
  margin-top: var(--spacer-400);
  margin-bottom: var(--spacer-400);
}

.BlogIndex_list__dbUEE {
  gap: var(--spacer-400);
  margin-top: var(--spacer-600);
}

@media (max-width: 63.999rem) {
  .BlogIndex_headerWrapper__WJHii::before {
    display: none;
  }
}

@media (min-width: 48rem) {
  .BlogIndex_headerWrapper__WJHii {
    display: grid;
    gap: var(--spacer-200);
    grid-template-columns: 2fr 33%;
  }
}

@media (min-width: 64rem) {
  .BlogIndex_headerWrapper__WJHii {
    grid-template-columns: 4fr 20%;
  }

  .BlogIndex_list__dbUEE:not(.BlogIndex_list--emphasize__fNrOm) {
    gap: var(--spacer-600) var(--spacer-700);
    grid-template-rows: masonry;
  }

  .BlogIndex_list--emphasize__fNrOm {
    grid-template-columns: 3fr 2fr;
  }

  .BlogIndex_list--emphasize__fNrOm > :first-child {
    grid-row: 1/4;
  }
}

}
